import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { saveAs } from 'file-saver';
import { Dayjs } from 'dayjs';

import { Input, Table, Select, DatePicker, Flex, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
    LoadingOutlined,
    ReloadOutlined,
    CarOutlined,
    FilterFilled,
    EyeFilled,
    UnorderedListOutlined,
    FileExcelOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';
import BoxGroupCard from '@controls/box-group-card/box-group-card';

import { serverFetch } from '@src/core/server';

import { exception } from '@extensions/notification';
import { getEnumList, delayAction, dataURItoBlob } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { IUserSession } from '@entities/user-session';
import { ITruckFilter } from '@entities/truck-filter';
import { ICountry } from '@entities/country';
import { ITruck } from '@entities/truck';
import { IConsignment } from '@entities/consignment';
import { IBillFilter } from '@entities/bill-filter';

import { TruckType } from '@enums/truck-type';
import { TruckStatus, enumLabel as truckStatusLabel } from '@enums/truck-status';
import { IEnumItem } from '@enums/enum-item';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ICityHeader {
    name?: string;
    totalQty: number;
    consignments: Array<IConsignment>;
}

const filterContext: string = 'ImportTrucks';

const Trucks = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    console.log('ID123', userSession.userId);

    const initFilter: ITruckFilter = {
        userId: userSession.userId,
        type: TruckType.Import,
        statuses: [TruckStatus.New, TruckStatus.OnWay],
        isArchived: false,
    };

    const { RangePicker } = DatePicker;

    const d = useAppDispatch();

    const [api, notificationContextHolder] = notification.useNotification();

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const filter = useAppSelector<ITruckFilter>((s) => s.filters[filterContext]);

    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [currentTruck, setCurrentTruck] = useState<ITruck>();
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [statuses] = useState<Array<IEnumItem>>(getEnumList(TruckStatus, truckStatusLabel));

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setCountries([]);
        setTrucks([]);

        console.log('filter123', filter);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('trucks/import', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0]);
                setTrucks(result[0][1] || []);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onExport = () => {
        let billFilter: IBillFilter = {
            truckIds: selectedIds as Array<string>,
            userId: userSession.userId,
            completedOnly: true,
            isPaid: false,
            isArchived: false,
        };
        serverFetch('bills/export', { method: 'GET', queryParams: billFilter })
            .then((data) => {
                let fileBody = `data:${data.contentType};base64,${data.fileContents}`;
                saveAs(dataURItoBlob(fileBody), data.fileDownloadName);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выгрузки счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Информация о машине',
                        key: 'view',
                        disabled: !currentTruck,
                        icon: <CarOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentTruck?.id}`);
                        },
                    },
                    {
                        label: 'Информация о грузе',
                        key: 'consignments',
                        disabled: !currentTruck,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/consignments/${currentTruck?.id}`);
                        },
                    },
                    {
                        label: 'Выгрузить счета в Excel',
                        key: 'exportToExcel',
                        icon: <FileExcelOutlined />,
                        disabled: selectedIds.length <= 0,
                        onClick: () => onExport(),
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                items={[
                    <Input
                        style={{ width: 130 }}
                        key='number'
                        placeholder='Машина'
                        value={filter?.number}
                        onChange={(data) => {
                            d(setFilter({ ...filter, number: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter?.consigneeCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Select
                        key='status'
                        placeholder='Статус машины'
                        value={filter?.statuses}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) => d(setFilter({ ...filter, statuses: value }, filterContext))}
                        options={statuses.map((s) => {
                            return { value: s.value, label: s.label };
                        })}
                    />,
                    <RangePicker
                        style={{ width: 220 }}
                        allowEmpty={[true, true]}
                        key='departure'
                        format='DD.MM.YYYY'
                        placeholder={['Отправка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={[
                            filter?.departureFrom ? dayjs(filter?.departureFrom) : null,
                            filter?.departureTo ? dayjs(filter?.departureTo) : null,
                        ]}
                        onChange={(value) => {
                            if (!value) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            departureFrom: undefined,
                                            departureTo: undefined,
                                        },
                                        filterContext
                                    )
                                );

                                return;
                            }

                            d(
                                setFilter(
                                    {
                                        ...filter,
                                        departureFrom: value[0]
                                            ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                        departureTo: value[1]
                                            ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                    },
                                    filterContext
                                )
                            );
                        }}
                    />,
                    <RangePicker
                        style={{ width: 220 }}
                        allowEmpty={[true, true]}
                        key='loading'
                        format='DD.MM.YYYY'
                        placeholder={['Отгрузка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={[
                            filter?.loadingFrom ? dayjs(filter?.loadingFrom) : null,
                            filter?.loadingTo ? dayjs(filter?.loadingTo) : null,
                        ]}
                        onChange={(value) => {
                            if (!value) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            loadingFrom: undefined,
                                            loadingTo: undefined,
                                        },
                                        filterContext
                                    )
                                );

                                return;
                            }

                            d(
                                setFilter(
                                    {
                                        ...filter,
                                        loadingFrom: value[0]
                                            ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                        loadingTo: value[1]
                                            ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                    },
                                    filterContext
                                )
                            );
                        }}
                    />,
                ]}
                onClear={() => d(setFilter(initFilter, filterContext))}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let item = trucks.find((o) => o.id === selectedRowKeys[0]);
            setCurrentTruck(item);
        } else {
            setCurrentTruck(undefined);
        }
    };

    const expandedConsignments = (record: ICityHeader) => {
        let consignments = record.consignments?.map((p) => {
            let boxGroups: any = {};
            let totalQty = 0;

            p.boxGroups?.map((b) => {
                if (!b.countryId) return;

                boxGroups[b.countryId] = b.qty;
                totalQty += b.qty || 0;
            });

            p = {
                ...p,
                ...boxGroups,
                totalQty: totalQty,
            };
            return p;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                width: 80,
                align: 'center',
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 130,
            },

            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 110,
                dataIndex: 'totalQty',
                onCell: (record) => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
            {
                dataIndex: 'countries',
                render: (_, record) => {
                    return (
                        <Flex gap='small'>
                            {countries.map((c) => {
                                let boxGroups = record.boxGroups?.filter((bg) => bg.countryId === c.id);
                                if (!boxGroups || boxGroups.length <= 0) return <></>;

                                return <BoxGroupCard country={c} boxGroups={boxGroups} />;
                            })}
                        </Flex>
                    );
                },
            },
        ];

        /*     countries.map((c) => {
            columns.push({
                title: c.name,
                width: 230,
                render: (_, record) => {
                    let boxGroups = record.boxGroups?.filter((bg) => bg.countryId === c.id);
                    if (!boxGroups || boxGroups.length <= 0) return <></>;

                    return boxGroups.map((boxGroup) => {
                        return (
                            <Form
                                colon={false}
                                key={boxGroup.id}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                                style={{ marginBottom: 5 }}
                            >
                                <Form.Item
                                    label={
                                        <Tooltip title='Количество'>
                                            <BoxesIcon />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                    style={{ fontWeight: 600 }}
                                >
                                    {boxGroup.qty}
                                </Form.Item>
                                {boxGroup.loadingOn && (
                                    <Form.Item
                                        label={
                                            <Tooltip title='Дата отгрузки'>
                                                <CalendarOutlined style={{ fontSize: 18 }} />
                                            </Tooltip>
                                        }
                                        className='country-form-item'
                                    >
                                        <span style={{ backgroundColor: '#ffdd2d', padding: '0 4px' }}>
                                            {dayjs.utc(boxGroup.loadingOn).local().format('DD.MM.YYYY')}
                                        </span>
                                    </Form.Item>
                                )}
                                {boxGroup.awbNumber && (
                                    <Form.Item label='AWB' className='country-form-item'>
                                        <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{boxGroup.awbNumber}</span>
                                    </Form.Item>
                                )}
                            </Form>
                        );
                    });
                },
            });
        }); */

        return <Table rowKey='id' size='small' columns={columns} dataSource={consignments} pagination={false} />;
    };

    const expandedCities = (record: ITruck) => {
        let cities: Array<ICityHeader> = [];
        let cityNames = [];

        record.consignments?.map((e) => {
            let city = cities.find((c) => c.name === e.cityName);
            if (!city) {
                city = {
                    name: e.cityName,
                    totalQty: 0,
                    consignments: [],
                };

                cities.push(city);
                cityNames.push(e.cityName);
            }

            e.boxGroups?.map((b) => {
                if (city) {
                    city.totalQty += b.qty || 0;
                }
            });

            city.consignments.push(e);
        });

        const columns: ColumnsType<ICityHeader> = [
            {
                title: 'Город',
                dataIndex: 'name',
                width: 210,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 110,
                dataIndex: 'totalQty',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
            },
            { title: ' ' },
        ];

        return (
            <Table
                rowKey='name'
                size='small'
                columns={columns}
                dataSource={cities}
                pagination={false}
                expandable={{ expandedRowRender: expandedConsignments /* , defaultExpandedRowKeys: cityNames  */ }}
            />
        );
    };

    const renderTable = () => {
        let lines: Array<ITruck> = trucks.map((t) => {
            let totalQty = 0;

            t.consignments?.map((p) => {
                p.boxGroups?.map((b) => {
                    totalQty += b.qty || 0;
                });
            });

            let item: ITruck = {
                ...t,
                totalQty: totalQty,
            };
            return item;
        });

        const columns: ColumnsType<ITruck> = [
            { title: 'Номер машины', dataIndex: 'number', key: 'number', width: 150 },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                width: 150,
                render: (_, record) => {
                    return (
                        <>
                            {record.status ? truckStatusLabel(record.status) : 'Не назначена'}
                            {record.trackingUrl && (
                                <Tooltip placement='topRight' title='Показать на карте'>
                                    <a className='trackingUrl' href={record.trackingUrl} target='_blank' rel='noreferrer'>
                                        <EyeFilled />
                                    </a>
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Дата отправки',
                width: 130,
                render: (_, record) => {
                    return record.departureOn && dayjs.utc(record.departureOn).local().format('DD.MM.YYYY');
                },
            },
            {
                title: 'Общее кол-во / шт',
                dataIndex: 'totalQty',
                key: 'totalQty',
                width: 160,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#ffee96',
                        fontWeight: 700,
                    },
                }),
            },
            { title: ' ' },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{ expandedRowRender: expandedCities }}
                dataSource={lines}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? '240px' : '175px'})`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {notificationContextHolder}
        </>
    );
};

export default Trucks;
